<template>
    <div class="">

        <div class="">
            <div class="profile-header profile-bg">
                <div class="row">
                    <div class="col-md-2 text-right pl-5 pr-0 mr-0">
                        <img style="height:100%;width:100%;border-radius: 15%;" :src="getClubLogoImg()" alt="User Avatar" class=" mr-3 ">
                    </div>
                    <div class="col-md-6 pl-5">
                        <div class="profiler-detail">
                            <h3>{{ clubInfo.name }}</h3>
                            <h5>{{ clubType[clubInfo.type] }}</h5>
                            <div class="b-der"></div>
                             <div class="d-flex">
                                 <div class="reg_date mt-3 mr-3">
                                    <div class="d-flex align-items-center">
                                        <div class="up_icon mr-4">
                                            <img src="/dist/img/profile/ico-registerd-date.png">
                                        </div>
                                        <div class="up_text">
                                            <span class="lable">Registered Date</span>
                                            <span> {{ clubInfo.start_date || "N/A"}}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="ex_date mt-3 ml-3">
                                    <div class="d-flex align-items-center">
                                        <div class="up_icon mr-4">
                                             <img src="/dist/img/profile/ico-expired-date.png">
                                        </div>
                                        <div class="up_text">
                                            <span  class="lable">Expiration Date</span>
                                            <span> {{ clubInfo.valid_thru || "N/A"}}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                             </div>
                            
                        </div>
                    </div>
                </div>
            </div>

             <div class="row profile-body " style="height:;">
                <div class="col-md-12">
                    <div class="card mt-3">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>My Membership</h3>
                            </div>

                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive pad-r pad-l ">
                            <b-table
                                id="my-table"
                                :items="allRegistrations"
                                :fields="tblFields"
                                class="mt-3"
                                hover
                                show-empty
                            >
                                <template
                                    :fields="items"
                                >
                                    <div class="text-left py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                            <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                            <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                                            </button>
                                            <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                                        </div>
                                    </div>
                                </template>
                                <template #empty="scope">
                                    <h4>{{ scope.emptyText }}</h4>
                                </template>
                                 <template #cell(waiver)="row">
                                    <div class="text-left py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                            <button @click="downloadDocs(row.item.id, 'waiver')"
                                                    class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                                        </div>
                                    </div>
                                </template>

                                <template #cell(receipt)="row">
                                    <div class="text-left py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                            <button @click="downloadDocs(row.item.id, 'payment')"
                                                    class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                                        </div>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>

      <div class="row profile-body  mb-3" style="height:;">
        <div class="col-md-12">
          <div class="card mt-1 ">
            <div class="card-header">
              <div class="header-title">
                <h3>Donations</h3>
              </div>

            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive pad-r pad-l ">
              <b-table
                  id="my-table"
                  :items="allDonations"
                  :fields="tblFieldsDonations"
                  :sort-by="tblSortByDonations"
                  :sort-desc=true
                  class="mt-3"
                  hover
                  show-empty
              >
                <!-- <template #cell(donation_discipline)="row">
                  {{ listOfDonationSportDiscipline[row.item.donation_discipline] }}
                </template> -->
                <template #cell(sports_discipline)="row">
                    {{ listOfDonationSportDiscipline[row.value] }}
                </template>
                <template #empty="scope">
                  <h4>{{ scope.emptyText }}</h4>
                </template>
                <template #cell(receipt)="row">
                  <div class="text-left py-0 align-middle">
                    <div class="btn-group btn-group-sm">
                      <button @click="downloadDonarionDocs(row.item.id)"
                              class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>

      <!-- Purchase History -->
      <div class="row profile-body" id="purchaseHistorySection" v-if="this.getSiteProps('general.purchaseHistory')">
            <div class="col-md-12">
                <div class="card mt-3">
                    <div class="card-header">
                        <div class="header-title">
                            <h3>Purchase History</h3>
                        </div>
                    </div>
                    
                    <div class="card-body mt-4 pt-0">
                        <div class="d-flex">
                        <div class="col-md-3">
                            <b-form inline>
                                <label class="mr-sm-2 font-weight-normal">Show</label>
                                <b-form-select
                                id="inline-form-custom-select-pref"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="purchaseHistory.perPage"
                                :options="purchaseHistory.viewEntryOptions"
                                size="md"
                                ></b-form-select
                                >entries
                            </b-form>
                            </div>
                            <div class="col-md-6 d-inline-flex">
                                <div class="form-inline mr-1">
                                    <label class="form-label mr-2">From Date</label>
                                    <div class="form-group birthdate">
                                        <div class="birthdaypicker">
                                            <date-pick
                                                v-model="purchaseHistory.filter.startDate"
                                                :format="'MM/DD/YYYY'"
                                                :displayFormat="'MM/DD/YYYY'"
                                                placeholder="start"
                                                v-mask="'##/##/####'"
                                                :parseDate="parseDatePick"
                                            ></date-pick>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-inline">
                                    <label class="form-label mr-2">To Date</label>
                                    <div class="form-group birthdate">
                                        <div class="birthdaypicker">
                                            <date-pick
                                                v-model="purchaseHistory.filter.endDate"
                                                :format="'MM/DD/YYYY'"
                                                :displayFormat="'MM/DD/YYYY'"
                                                placeholder="End"
                                                v-mask="'##/##/####'"
                                                :parseDate="parseDatePick"
                                            ></date-pick>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                            <b-input-group size="md">
                                <b-form-input
                                class="border border-secondary"
                                id="filter-input"
                                v-model="purchaseHistory.filter.search"
                                type="search"
                                placeholder="Search"
                                ></b-form-input>
                            </b-input-group>
                        </div>
                    </div>
                    <b-table
                        id="purchaseHistoryTable"
                        :per-page="purchaseHistory.perPage"
                        :current-page="purchaseHistory.currentPage"
                        :items="purchaseHistory.data"
                        :fields="purchaseHistoryTbl"
                        class="mt-3"
                        :busy="!purchaseHistory.loaded"
                        :filter="purchaseHistory.filter"
                        :filter-included-fields="purchaseHistory.filterOn"
                        :filter-function="customFilter"
                        responsive='true'
                        @filtered="onFilteredPurchaseHistory"
                        hover
                        show-empty
                    >
                    <template #cell(receipt)="row">
                        <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm" v-if="row.item.transaction_type == 'donation'">
                                <button @click="downloadDonarionDocs(row.item.donation.id)"
                                        class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                            </div>
                            <div class="btn-group btn-group-sm" v-else-if="row.item.transaction_type == 'registraion' && !row.item.name.includes('Discount Coupon')">
                                <button @click="downloadDocs(row.item.ref_regid, 'payment')"
                                        class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                            </div>
                          <div class="btn-group btn-group-sm" v-else-if="['insurance','sanction'].includes(row.item.transaction_type)">
                            <button @click="downloadOtherDocs(row.item.ref_regid, row.item.transaction_type, row.item.transaction_id)"
                                    class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                          </div>
                        </div>
                    </template>
                    </b-table>
                    <br />
                    <b-pagination
                        v-if="purchaseHistory.loaded"
                        v-model="purchaseHistory.currentPage"
                        :total-rows="purchaseHistoryRows"
                        :per-page="purchaseHistory.perPage"
                        prev-text="Previous"
                        next-text="Next"
                        aria-controls="purchaseHistoryTable"
                        class="float-right custom-color"
                        hide-goto-end-buttons
                    ></b-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import axios from 'axios';

export default {
    name: "clubMembership",
    // components: {navigation, sidebar, dashboardFooter},
    data() {
        return {
            clubInfo: [],
            allDonations: [],
            tblFields: [
                /*{ key: 'member_id', sortable: true, label: 'Member #' },*/
                { key: 'registered_as', sortable: true, label: 'Registration Type' },
                { key: 'transaction_id', sortable: true, label: 'Transaction Id' },
                { key: 'transaction_date', sortable: true, label: 'Transaction Date' },
                { key: 'start_date', sortable: true, label: 'Effective From' },
                { key: 'expires', sortable: true, label: 'Effective To' },
                { key: 'status', sortable: true, label: 'Status' },
                { key: 'waiver', sortable: false, label: 'Waiver' },
                { key: 'receipt', sortable: false, label: 'Receipt'},
            ],
            tblSortBy:'expires',
            allRegistrations: {},
            tblFieldsDonations: [
              { key: 'sports_discipline', sortable: true, label: 'Sports Discipline' },
              { key: 'cost', sortable: true, label: 'Donation Amount (USD)' },
              { key: 'transaction_code', sortable: true, label: 'Transaction Id' },
              { key: 'transaction_time', sortable: true, label: 'Paid At', formatter: "usdate" },
              { key: 'receipt', sortable: false, label: 'Receipt'},
            ],
          tblSortByDonations:'amount',
            purchaseHistoryTbl:[
                {key: 'transaction_date', sortable: true, label: 'Transaction Date',
                    formatter: (value) => {
                    return moment(String(value)).format("MM/DD/YYYY");
                    }
                },
                {key: 'transaction_id', sortable: true, label: 'Transaction ID'},
                {key: 'product', sortable: true, label: 'Product', 
                formatter: (value , key , item) => {
                    let couponValue = value + ' ('+item.name+')';
                    return item.name != null && item.name.includes('Discount Coupon') ? couponValue : value;
                    }
                },
                {key: 'cost', sortable: false, label: 'Cost', 
                    formatter: (value) => {
                    return '$'+value;
                    }
                },
                { key: 'receipt', sortable: false, label: 'Receipt'},
            ],
            purchaseHistory : {
                data: [],
                totalRows: 1,
                loaded: false,
                perPage: 10,
                currentPage: 1,
                filter: {
                    search: '',
                    startDate: '',
                    endDate: '',
                },
                filterOn: [],
                viewEntryOptions: [
                    { value: 5, text: "5" },
                    { value: 10, text: "10" },
                    { value: 20, text: "20" },
                    { value: 50, text: "50" }
                ]
            },
            filterValid: true
        }
    },
    methods: {
        formatName(value) {
            return value || 'N/A'
        },
        formatlongDate(datevalue){
            var date = moment(datevalue);
            if(date.isValid()){
                return date.format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },
        loadRegistrationHistory() {
            axios.get(this.basePath+"api/club/registration/history",{ headers:this.clubHeaders })
                .then((response)=>{
                    const resp  = response.data.data;
                    this.allRegistrations = resp;
                }).catch((error) =>{
                console.log(error);
            });
        },
        loadDonationHistory() {
            let donationURL = this.basePath+"api/club/donations";
            if(this.subdomain == 'alliancefastpitch')
            {
                donationURL = this.basePath+"api/club/donations/club"; 
            }
            axios.get(donationURL,{ headers:this.clubHeaders })
                .then((response)=>{
                this.allDonations = response.data.data;
                }).catch((error) =>{
            console.log(error);
            });
        },
        downloadDocs(item, type) {
            axios.get(this.basePath + 'api/club/download/'+type+'/'+item, {headers:this.clubHeaders, responseType: 'arraybuffer'})
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/pdf' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "club_"+this.clubInfo.name+"_"+'Reg'+item+"_"+type+'.pdf';
                    link.click();
                    link.remove();
                }).catch((error)=>{
                console.log("error download"+error);
            })
        },
      downloadOtherDocs(item, type, transaction_id){
        this.downloadPdf(this.basePath + 'api/club/clubreceiptDownload/'+type+'/'+item+'/null/'+transaction_id, 'Reg'+item+"_"+type );
      },
        getClubLogoImg(){
            return (this.clubInfo.logo!=null?this.basePath +this.clubInfo.logo:'/dist/img/avatarimg.png')+'?v='+moment().format('x');
        },
        loadClubInfo() {
            this.clubInfo.valid_thru = "mm/dd/yyyy";
             axios
            .get(this.basePath + "api/club/info", { headers: this.clubHeaders })
            .then((response) => {
                const resp = response.data.data;
                this.clubInfo.valid_thru = moment(resp.expires_at).format('MM/DD/YYYY');
                this.clubInfo.start_date = moment(resp.reg_date).format('MM/DD/YYYY');
            }).catch((error) => {
                console.log(error);
            });
        },
        downloadDonarionDocs(item) {
          this.downloadPdf(this.basePath + 'api/club/donation/'+item, 'Donation');
        },
        downloadPdf(url, name){
          axios.get(url, {headers:this.clubHeaders, responseType: 'arraybuffer'})
              .then(response => {
                let blob = new Blob([response.data], { type: 'application/pdf' });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = this.clubInfo.name+"_"+name+'.pdf';
                link.click();
                link.remove();
              }).catch((error)=>{
            console.log("error download");
          });
        },
        loadPurchaseHistory(){
            let memberId = this.clubInfo.club_membership_id.id;
            axios.get(this.basePath+"api/club/purchase-history/"+ memberId,{ headers:this.clubHeaders }).then(response => {
                this.purchaseHistory.data = response.data.data;
                this.purchaseHistory.loaded = true;
                this.purchaseHistory.totalRows = this.purchaseHistory.data.length;
            })
            .catch(err => {
                console.log(err);
            })
        },
        onFilteredPurchaseHistory(filteredItems,data) {       
            this.purchaseHistory.totalRows = filteredItems.length;
            this.purchaseHistory.currentPage = 1;
        },
        customFilter(rows, filter){
            let transactionValid = false;
            let searchFlag = false;
            let startDateFlag = false;
            let endDateFlag = false;
            let compareDateFlag = false;

            let filterNames = rows.name + rows.product + rows.transaction_id;
            if(filter.search != null && filter.search.trim() != ''){
                searchFlag = true;
               if( filterNames.toLowerCase().includes(filter.search.trim().toLowerCase())){
                   transactionValid =  true;
               }
            }
            
            if(filter.startDate != null && filter.startDate.trim() != '' && (filter.endDate.trim() == '' ) ){
                const start_date = moment(filter.startDate).format("MM/DD/YYYY");
                const filter_start_date = moment(rows.transaction_date).format("MM/DD/YYYY");
               
                if( moment(start_date).isSame(filter_start_date)  ){
                    transactionValid =  true;
                }else if( moment(start_date).isBefore(filter_start_date) ){
                    transactionValid =  true;
                }
                startDateFlag = true;
            }

            if(filter.endDate != null && filter.endDate.trim() != '' && filter.startDate.trim() == ''){
                const end_date = moment(filter.endDate).format("MM/DD/YYYY");
                const filter_end_date = moment(rows.transaction_date).format("MM/DD/YYYY");
               
                if( moment(end_date).isSame(filter_end_date)  ){
                    transactionValid =  true;
                }else if( moment(end_date).isAfter(filter_end_date) ){
                    transactionValid =  true;
                }
                endDateFlag = true;
            }

            if(filter.endDate.trim() != '' && filter.startDate.trim() != '' ){
                const filter_transaction_date = moment(rows.transaction_date).format("MM/DD/YYYY");
                const start_date = moment(filter.startDate).format("MM/DD/YYYY");
                const end_date = moment(filter.endDate).format("MM/DD/YYYY");

                if( moment(start_date).isSame(filter_transaction_date)  ){
                    transactionValid =  true;
                }else if( moment(end_date).isSame(filter_transaction_date)  ){
                    transactionValid =  true;
                }else if( moment(filter_transaction_date).isBetween(start_date, end_date)){
                    transactionValid =  true;
                }
                compareDateFlag = true;
            }
            
            if( searchFlag || startDateFlag || endDateFlag || compareDateFlag){
                return transactionValid;
            }
            return  true;
        }
    },
    computed: {
        purchaseHistoryRows() {
            return this.purchaseHistory.data.length;
        },
    },
    mounted() {
        this.loadRegistrationHistory();
        this.loadDonationHistory();
        this.clubInfo = JSON.parse(localStorage.getItem('team'));
        this.clubInfo.valid_thru = moment(this.clubInfo.valid_thru).format("MM/DD/YYYY");
        this.clubInfo.start_date = moment(this.clubInfo.start_date).format("MM/DD/YYYY");
        this.getClubType();
        this.loadClubInfo();
        this.loadPurchaseHistory();
    }
}
</script>
